/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-05 14:13:30
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-14 10:34:52
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/DataRank/typeList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const typeList = [
  {
    name: '柱形图',
    id: 'bar'
  },
  {
    name: '饼状图',
    id: 'piev'
  },
  {
    name: '列表',
    id: 'row'
  }
]

// type 0 流量  1 预算  2 QPS
export const targetList = [
  // 流量
  { name: '请求', id: 'request', sum: 'sumRequest', type: 0 },
  { name: '填充', id: 'response', sum: 'sumResponse', type: 0 },
  { name: '填充率', id: 'requestFilledRate', sum: 'sumRequestFilledRate', type: 0 },
  { name: '竞胜率', id: 'bidSuccessRate', sum: 'sumBidSuccessRate', type: 0 },
  { name: '展示', id: 'impress', sum: 'sumImpress', type: 0 },
  { name: '展示率', id: 'impressRate', sum: 'sumImpressRate', type: 0 },
  { name: '点击', id: 'click', sum: 'sumClick', type: 0 },
  { name: '点击率', id: 'clickRate', sum: 'sumClickRate', type: 0 },
  { name: '出价eCPM', id: 'priceEcpm', sum: 'sumPriceEcpm', type: 0 },
  { name: '成交eCPM', id: 'ecpm', sum: 'sumEcpm', type: 0, noRota: 1 },
  { name: 'CPC', id: 'cpc', sum: 'sumCpc', type: 0, noRota: 1 },
  { name: '请求底价', id: 'bidfloor', sum: 'sumBidfloor', type: 0, noRota: 1 },
  { name: '填充耗时', id: 'responseTime', sum: 'sumResponseTime', type: 0, noRota: 1 },
  { name: '超时率', id: 'adxTimeoutRate', sum: 'sumAdxTimeoutRate', type: 0, noRota: 1 },
  { name: '百万次请求收益', id: 'requestPrice', sum: 'sumRequestPrice', type: 0, noRota: 1 },

  // 预算
  { name: '请求', id: 'advRequest', sum: 'sumAdvRequest', type: 1 },
  { name: '填充', id: 'advResponse', sum: 'sumAdvResponse', type: 1 },
  { name: '填充率', id: 'advRequestFilledRate', sum: 'sumAdvRequestFilledRate', type: 1 },
  { name: '竞胜率', id: 'advBidSuccessRate', sum: 'sumAdvBidSuccessRate', type: 1 },
  { name: '展示', id: 'advImpress', sum: 'sumAdvImpress', type: 1 },
  { name: '展示率', id: 'advImpressRate', sum: 'sumAdvImpressRate', type: 1 },
  { name: '点击', id: 'advClick', sum: 'sumAdvClick', type: 1 },
  { name: '点击率', id: 'advClickRate', sum: 'sumAdvClickRate', type: 1 },
  { name: '出价eCPM', id: 'askPrice', sum: 'sumAskPrice', type: 1 },
  { name: '成交eCPM', id: 'advEcpm', sum: 'sumAdvEcpm', type: 1, noRota: 1 },
  { name: 'CPC', id: 'advCpc', sum: 'sumAdvCpc', type: 1, noRota: 1 },
  { name: '请求底价', id: 'advBidfloor', sum: 'sumAdvBidfloor', type: 1, noRota: 1 },
  { name: '填充耗时', id: 'advResponseTime', sum: 'sumAdvResponseTime', type: 1, noRota: 1 },
  { name: '超时率', id: 'dspTimeoutRate', sum: 'sumDspTimeoutRate', type: 1, noRota: 1 },
  { name: '百万次请求收益', id: 'advRequestPrice', sum: 'sumAdvRequestPrice', type: 1, noRota: 1 },

  // QPS
  { name: '开放流量费用', id: 'openPublisherPrice', sum: 'sumOpenPublisherPrice', type: 2 },
  { name: '开放预算费用', id: 'openDspPrice', sum: 'sumOpenDspPrice', type: 2 },
  { name: 'QPS费用', id: 'qpsPrice', sum: 'sumQpsPrice', type: 2 }
]
